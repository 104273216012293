<template>
  <div
    class="border border-opacity-50 rounded capitalize text-center w-full"
    :class="{
      'text-activity border-blue-primary bg-blue-primary-light': userType === 'prospect',
      'text-orange-primary border-orange-primary bg-orange-primary-light': userType === 'client',
      'text-main-green border-main-blue': userType === 'lead',
      'text-main border-main-blue': userType === 'pre_lead',
    }"
  >
    <span>
      {{ title }}
    </span>
  </div>
</template>

<script>
import { computed } from 'vue'
import ROLE from '../../enums/roles'

export default {
  name: 'SwdTypeUserLabel',
  props: {
    userType: {
      type: String,
      require: true,
      default: 'prospect',
    },
  },
  setup(props) {
    const title = computed(() => {
      if (props.userType === ROLE.PROSPECT) return 'Opportunity'
      if (props.userType === ROLE.PRE_LEAD) return 'Prospect 1app'
      if (props.userType === ROLE.LEAD) return 'Prospect'
      return props.userType
    })

    return {
      title,
    }
  },
}
</script>
