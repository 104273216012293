<template>
  <el-dialog
    v-model="dialogVisible"
    title="Select Account"
    top="30vh"
    width="55%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div class="flex flex-wrap">
      <div
        v-for="item in data"
        :key="item.id"
        class="border border-main-gray p-2 m-2 rounded-lg hover:border-border-blue"
      >
        <div class="flex justify-center items-center">
          <img width="50" height="50" src="../../assets/img/default-avatar.png" alt="icon-pdf" />
        </div>
        <div class="py-2 text-center text-main cursor-default">
          {{ item.name }}
        </div>
        <div class="flex flex-col pb-1">
          <SwdLinearProgress
            v-if="item.type !== 'client'"
            :stroke-width="16"
            :percentage="Math.round(item.onboarding)"
          />
        </div>
        <div class="flex flex-col">
          <div class="text-center">
            <el-button type="success" plain size="small" :icon="Link" class="w-28" @click="chooseClient(item)">
              Choose
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { useLogout } from '@/api/authentication/use-logout'
import SwdLinearProgress from '@/components/Global/SwdLinearProgress.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useSetUpdateAbility } from '@/hooks/use-set-update-ability'

export default defineComponent({
  name: 'ChooseAccount',
  components: {
    SwdLinearProgress,
  },
  setup() {
    const store = useStore()
    const { fetching } = useLogout()
    const data = ref([])
    const dialogVisible = ref(false)
    const router = useRouter()

    watchEffect(() => {
      const { multipleAccount, termsAndConditions, isMultipleVerified } = store.state.globalComponents
      data.value = multipleAccount
      dialogVisible.value = multipleAccount?.length > 1 && termsAndConditions && !isMultipleVerified
      if (dialogVisible.value) {
        store.commit('globalComponents/setIsLoadingApp', dialogVisible.value)
      }
    })

    const chooseClient = async (item) => {
      const { member_id, type, id } = item

      store.commit('globalComponents/setRole', type)
      store.commit('globalComponents/setMemberId', member_id)
      store.commit('globalComponents/setLeadId', store.state.globalComponents.userId)
      store.commit('globalComponents/setCurrentTypeUser', type)
      store.commit('globalComponents/setIsLoadingApp', false)
      store.commit('globalComponents/setMultipleAccountVerified', true)

      const getUserId = computed(() => {
        return store.state.globalComponents.role === 'client'
          ? store.state.globalComponents.clientId
          : store.state.globalComponents.memberId
      })

      const { setUpdateAbility } = useSetUpdateAbility()
      await setUpdateAbility()

      if (type === 'client') {
        store.commit('globalComponents/setClientId', member_id)
        router.push({ name: `client/member-details`, params: { id: getUserId.value } })
      } else if (type === 'lead' || type === 'prospect') {
        store.commit('globalComponents/setClientId', id)
        router.push({ name: 'lead/dashboard' })
      }
    }

    return {
      dialogVisible,
      chooseClient,
      fetching,
      data,
    }
  },
})
</script>

<style>
.dialog-terms {
  border-radius: 0;
}

.term-item {
  padding-bottom: 10px;
  word-break: break-word;
}
</style>
