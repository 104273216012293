export default {
  CEO: 'ceo',
  ADVISOR: 'advisor',
  ADMIN: 'admin',
  CLIENT: 'client',
  LEAD: 'lead',
  ASSISTANT: 'assistant',
  PROSPECT: 'prospect',
  PRE_LEAD: 'pre_lead',
  SUPPORT: 'support',
  MEMBER: 'member',
}
