export function phoneNumber(str) {
  // Remove alphabetical characters from the input
  let cleaned = str.replace(/[A-Za-z]/g, '')

  // Define a regular expression to match all special characters except parentheses, space, and hyphen
  let regex = /[^0-9()\s-]/g

  // Remove special characters except parentheses, space, and hyphen
  cleaned = cleaned.replace(regex, '')

  // Limit the length to 14 characters
  if (cleaned.length > 14) {
    cleaned = cleaned.substring(0, 14)
  }

  // Update the formatted phone number in the appropriate variable
  return cleaned
}
