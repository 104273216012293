import Login from '@/layouts/Login.vue'

export const magicEmailRoute = {
  path: '/magic',
  name: 'magic',
  component: Login,
  meta: { publicRoute: true },
  children: [
    {
      path: 'email',
      name: 'magic-email',
      meta: { publicRoute: true },
      component: () => import(/* webpackChunkName: "MagicEmail" */ '../../views/MagicEmailForm.vue'),
    },
    {
      path: 'login',
      name: 'magic-login',
      component: () => import(/* webpackChunkName: "Logout" */ '../../views/MagicLogin.vue'),
    },
  ],
}
