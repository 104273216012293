<template>
  <div></div>
</template>
<script>
import { ElMessage } from 'element-plus'
import { tokenStorage } from '../api/api-client/TokenStorage'

export default {
  name: 'AutoLogout',
  data: function () {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      logoutTimer: null,
      warningZone: false,
      minutes: 60, // set time in minute here.
    }
  },
  mounted() {
    this.events.forEach(function (events) {
      window.addEventListener(events, this.resetTimer)
    }, this)
    this.setTimers()
  },
  unmounted() {
    this.events.forEach(function (events) {
      window.removeEventListener(events, this.resetTimer)
    }, this)
    this.resetTimer()
  },
  methods: {
    setTimers: function () {
      let time = this.minutes * 60 * 1000 //  minutes in mili seconds.
      this.logoutTimer = setTimeout(this.logoutUser, time)
      this.warningZone = false
    },
    logoutUser: function () {
      const excludeRoute = [
        'loginform',
        'otp',
        'forgotpassword',
        'magic-email',
        'magic-login',
        'passwordreset',
        'passwordcreate',
        '503',
      ]
      if (!excludeRoute.includes(this.$route.name)) {
        setTimeout(function () {
          sessionStorage.clear()
          tokenStorage.setByKey('otp_verified', false)
          window.location.href = '/logout'
        }, 7000)
        ElMessage.error(
          'Oops, you have been In-Active for last ' + this.minutes + ' minutes. Session Expired Redirecting to Login.'
        )
      }
    },
    resetTimer: function () {
      clearTimeout(this.logoutTimer)

      this.setTimers()
    },
  },
}
</script>
