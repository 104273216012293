import { rulesName } from './fields/rules-name'
import { rulesEmail } from './fields/rules-email'
import { rulesPhone } from './fields/rules-phone'

const rules = {
  name: rulesName,
  email: rulesEmail,
  phone: rulesPhone,
}

export { rules }
