/* eslint-disable no-undef */
import { reactive } from 'vue'
import ENV from '../enums/environment'

export function useShowContentEnv() {
  const showContent = reactive({
    testNotificationsBtn: false,
    testSentryBtn: false,
    showRole: false,
    infoSalesforceStatus: false,
    showNewLeadBtn: false,
    showAdminPanelTestVideoBtn: false,
    showFixedAnnuity: true,
    showTestBtn: false,
    trcmURL: process.env.VUE_APP_LOCAL_TRCM_URL,
    showTasksBtn: false,
  })

  if (process.env.VUE_APP_ENV === ENV.DEV || process.env.NODE_ENV === ENV.DEV || process.env.NODE_ENV === ENV.LOCAL) {
    showContent.testNotificationsBtn = true
    showContent.testSentryBtn = true
    showContent.showRole = true
    showContent.infoSalesforceStatus = true
    showContent.showNewLeadBtn = true
    showContent.showAdminPanelTestVideoBtn = true
    showContent.showFixedAnnuity = true
    showContent.showTestBtn = true
    showContent.showTasksBtn = true
  }

  if (process.env.VUE_APP_ENV === ENV.DEV) {
    showContent.trcmURL = process.env.VUE_APP_DEV_TRCM_URL
  } else if (process.env.VUE_APP_ENV === ENV.STAGE) {
    showContent.trcmURL = process.env.VUE_APP_STAGE_TRCM_URL
  } else if (process.env.VUE_APP_ENV === ENV.PROD) {
    showContent.trcmURL = process.env.VUE_APP_PROD_TRCM_URL
  } else {
    showContent.trcmURL = process.env.VUE_APP_LOCAL_TRCM_URL
  }

  return { showContent }
}
