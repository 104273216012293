import { ElNotification } from 'element-plus'
import { isEmpty } from 'lodash-es'

export function showErrorResponse(error) {
  if (!isEmpty(error.errors)) {
    const messages = []
    const errors = Object.values(error.errors)
    errors.forEach((items) => {
      if (items.length) {
        items.forEach((item) => {
          messages.push(item)
        })
      } else {
        messages.push(items)
      }
    })
    messages.forEach((message) => {
      setTimeout(function () {
        ElNotification({
          title: 'Error',
          message: message,
          type: 'error',
          dangerouslyUseHTMLString: true,
        })
      }, 100)
    })
  } else {
    if (error != 'TypeError: NetworkError when attempting to fetch resource.') {
      ElNotification({
        title: 'Error',
        message: error.message,
        type: 'error',
        dangerouslyUseHTMLString: true,
      })
    }
  }
}
