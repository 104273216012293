<template>
  <div class="mr-2.5 flex items-center">
    <span class="text-xss text-main mr-1.5">Items per page</span>
    <div class="w-16">
      <SwdNativeSelect :options="actions" size="small" :init-value="initValue" @select="handleItemsPerPage" />
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'SwdItemsPerPage',
  props: {
    actionsItemsPerPage: {
      type: Array,
      require: true,
      default: () => [],
    },
    destination: {
      type: String,
      require: true,
      default: 'defaultItemsPerPage',
    },
  },
  emits: ['selectPage'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const routeParams = router.currentRoute.value.params
    const initValue = computed(() => String(store.state.globalComponents.itemsPerPage.values[props.destination]))
    const actions = computed(() => store.state.globalComponents.itemsPerPage.options)
    routeParams.p = ''
    const handleItemsPerPage = (command) => {
      router.push({ name: router.currentRoute.value.name, params: routeParams })
      store.commit('globalComponents/setDestinationItemsPerPage', {
        destination: props.destination,
        value: command,
      })
      emit('selectPage', 1)
    }

    return {
      handleItemsPerPage,
      initValue,
      actions,
    }
  },
}
</script>
