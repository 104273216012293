<template>
  <div class="p-2">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'SwdWrapper',
}
</script>
