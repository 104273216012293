<template>
  <div class="border border-border-blue rounded-md p-5 mb-4">
    <div class="flex items-center mb-5">
      <InlineSvg :src="IconDoneStep" />
      <div class="text-main text-xl font-semibold ml-2">Meeting Notes</div>
    </div>
    <div v-if="member.salesforce.meetingNotes.length">
      <el-collapse v-if="!isLoading" accordion>
        <el-collapse-item v-for="(row, index) in member.salesforce.meetingNotes" :key="index">
          <template #title>
            <div class="w-full grid grid-cols-3 gap-2">
              <div class="col-span-2">
                <span>Title : {{ row?.name }}</span>
              </div>
              <div class="text-right mr-2">
                Meeting Date : {{ getClientStartDate(row?.date) }} <br />
                <br />Created Date : {{ getClientStartDate(row?.created_at) }}
              </div>
            </div>
          </template>
          <div class="w-full text-xss text-main font-medium">
            <b>Notes : </b><br />
            <span class="whitespace-pre-line" v-html="row.notes"></span>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-skeleton v-else :rows="5" animated />
    </div>
    <div v-else class="text-center">
      <p class="text-main text-xss mt-3">No recently added meeting notes</p>
    </div>
  </div>
</template>

<script>
import IconDoneStep from '@/assets/svg/icon-done-step.svg'
import dayjs from 'dayjs'

export default {
  name: 'NotesInfo',
  props: {
    member: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {
    const getClientStartDate = (date) => {
      if (date) {
        return dayjs(date).format('MM/DD/YYYY')
      } else {
        return '-'
      }
    }

    return {
      IconDoneStep,
      getClientStartDate,
    }
  },
}
</script>
