<template>
  <el-pagination
    :current-page="options.current_page || options.currentPage"
    :page-size="options.perPage"
    :pager-count="5"
    :page-count="options.total_pages"
    layout="prev, pager, next"
    :total="options.total"
    @current-change="handleCurrentChange"
  >
  </el-pagination>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  name: 'SwdPagination',
  props: {
    options: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  emits: ['selectPage'],

  setup(_, { emit }) {
    const router = useRouter()
    const routeParams = router.currentRoute.value.params

    const handleCurrentChange = (val) => {
      routeParams.p = decodeURI(val)
      router.replace({ name: router.currentRoute.value.name, params: routeParams })
      emit('selectPage', val)
    }
    return {
      handleCurrentChange,
    }
  },
}
</script>
