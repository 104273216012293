<template>
  <div :class="avatarClass">
    <img :src="getAvatar" class="h-full w-full object-cover rounded-[100px]" alt="avatar" />
  </div>
</template>
<script>
import SwdDefaultAvatar from '@/assets/img/default-avatar.png'
import { computed } from 'vue'

export default {
  name: 'SwdAvatar',
  props: {
    link: {
      type: String,
      require: false,
      default: () => '',
    },
    size: {
      type: String,
      require: false,
      default: 'medium',
      validator: (value) => {
        return ['large', 'medium', 'small'].includes(value)
      },
    },
  },

  setup(props) {
    const avatarClassMap = {
      large: 'h-14 w-14',
      medium: 'h-8 w-8',
      small: 'h-5 w-5',
    }

    const avatarClass = computed(() => {
      return avatarClassMap[props.size]
    })

    const getAvatar = computed(() => {
      if (props.link) return props.link
      return SwdDefaultAvatar
    })

    return {
      avatarClass,
      getAvatar,
      SwdDefaultAvatar,
    }
  },
}
</script>
