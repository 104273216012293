import { fetcher } from '@/api/fetcher/fetcher'

function updateTermAndConditions(form) {
  return fetcher({
    url: `/terms_and_conditions/accept`,
    data: form,
    options: { method: 'post' },
  })
}

export { updateTermAndConditions }
