/* eslint-disable no-undef */
import { toRefs, reactive } from 'vue'
import { apiClient } from '@/api/api-client/ApiClient'
import { StatusCodes } from 'http-status-codes'

export const useFetch = (url, options) => {
  const state = reactive({
    response: [],
    headers: {},
    error: null,
    fetching: false,
  })
  const fetchData = async (params) => {
    state.fetching = true
    state.error = null
    try {
      const body = JSON.stringify(params.body)
      const res = await apiClient.fetch(url, {
        ...options,
        ...params,
        body,
      })
      state.headers = Object.fromEntries(res.headers.entries())

      if (res.status === StatusCodes.NO_CONTENT) {
        state.response.status = StatusCodes.NO_CONTENT
        return
      }

      if (res.status === StatusCodes.SERVICE_UNAVAILABLE) {
        state.response.status = StatusCodes.SERVICE_UNAVAILABLE
        return
      }

      if (res.status === StatusCodes.ACCEPTED) {
        state.response.status = StatusCodes.ACCEPTED
        return
      }

      const json = await res.json()
      if (res.status === StatusCodes.OK) {
        state.response = json
        return
      }

      state.error = json.message
    } catch (error) {
      state.error = error
    } finally {
      state.fetching = false
    }
  }
  return { ...toRefs(state), fetchData }
}
