<template>
  <div class="flex justify-center items-center">
    <SwdButton primary small class="mr-2" @click="navigateCalendar">
      <el-badge class="item" type="primary">
        <InlineSvg :src="IconCalendar" class="cursor-pointer" />
      </el-badge>
    </SwdButton>
  </div>
</template>

<script>
import IconCalendar from '@/assets/svg/icon-calendar.svg'
import { useRouter } from 'vue-router'
export default {
  name: 'Calendar',
  setup() {
    const router = useRouter()
    const navigateCalendar = () => {
      router.push({ name: `advisor/calendar` })
    }
    return {
      IconCalendar,
      navigateCalendar,
    }
  },
}
</script>
