<template>
  <div>
    <el-result icon="error" sub-title="Oops! Something went wrong" />
  </div>
</template>
<script>
export default {
  name: 'SwdErrorBlock',
}
</script>
