<template>
  <el-dialog
    v-model="dialogVisible"
    title="Terms and Conditions"
    width="80%"
    center
    :show-close="false"
    class="dialog-terms"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-scrollbar height="400px" class="text-main pr-3">
      <div v-if="termsInfo !== null" v-html="termsInfo"></div>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <div class="flex justify-center">
          <div class="pr-3">
            <Button default-gray-btn text-btn="Cancel" @click="handleDecline" />
          </div>
          <SwdButton primary main :disabled="isLoadingUpdate" class="w-2/12" @click="handleAccept">
            <SwdSpinner v-show="isLoadingUpdate" class="mr-2" />
            I Agree
          </SwdButton>
        </div>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue'
import { useLogout } from '@/api/authentication/use-logout'
import { updateTermAndConditions } from '@/api/vueQuery/clients/update-term-condition'
import { useMutation } from 'vue-query'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ROLE from '../../enums/roles'

export default defineComponent({
  name: 'SwdModalTerms',
  setup() {
    const store = useStore()
    const router = useRouter()

    const { isLoading: isLoadingUpdate, mutateAsync: update } = useMutation(updateTermAndConditions)

    const dialogVisible = ref(false)
    const termsInfo = ref('')
    const { fetching, logout } = useLogout()

    watchEffect(() => {
      dialogVisible.value = store.state.globalComponents.termsAndConditions === false
      if (dialogVisible.value) {
        const { termsAndConditionsInfo } = store.state.globalComponents
        termsInfo.value = termsAndConditionsInfo
      }
    })

    const handleDecline = () => {
      logout()
    }

    const handleAccept = async () => {
      const res = await update({ terms_and_conditions: true })
      if (!('error' in res)) {
        const { noOfClientAccount } = store.state.globalComponents
        store.commit('globalComponents/setTermsAndConditions', true)
        store.commit('globalComponents/setTermsAndConditionsInfo', null)
        if (noOfClientAccount > 1) {
          store.commit('globalComponents/setMultipleAccountVerified', false)
          router.push({ name: 'choose_account' })
          return
        } else {
          store.commit('globalComponents/setIsLoadingApp', false)
          if (store.state.globalComponents.role === ROLE.LEAD) {
            router.push({ name: 'lead/dashboard' })
          }
          if (store.state.globalComponents.role === ROLE.CLIENT) {
            router.push({ name: `client/member-details`, params: { id: store.state.globalComponents.clientId } })
          }
        }
      }
    }

    return {
      dialogVisible,
      termsInfo,
      handleDecline,
      fetching,
      handleAccept,
      update,
      isLoadingUpdate,
    }
  },
})
</script>

<style>
.dialog-terms {
  border-radius: 0;
}

.term-item {
  padding-bottom: 10px;
  word-break: break-word;
}
</style>
