import { AbilityBuilder, Ability } from '@casl/ability'
import ability from '../services/ability'
import store from '@/store'
import ROLE from '../enums/roles'

export function useSetUpdateAbility() {
  const setUpdateAbility = () => {
    const role = store.state.globalComponents.role
    const { can, rules } = new AbilityBuilder(Ability)

    if (role === ROLE.ADVISOR) {
      can('advisor', 'all')
      can('advisor', 'update')
      can('advisor', 'read')
    }

    if (role === ROLE.CLIENT) {
      can('client', 'all')
      can('client', 'update')
      can('client', 'read')
    }

    if (role === ROLE.LEAD) {
      can('lead', 'all')
      can('lead', 'update')
      can('lead', 'read')
    }

    if (role === ROLE.ADMIN) {
      can('admin', 'all')
      can('admin', 'update')
      can('admin', 'read')
    }

    if (role === ROLE.CEO) {
      can('ceo', 'all')
      can('ceo', 'update')
      can('ceo', 'read')
    }

    if (role === ROLE.ASSISTANT) {
      can('assistant', 'all')
      can('assistant', 'update')
      can('assistant', 'read')
    }

    if (role === ROLE.SUPPORT) {
      can('support', 'all')
      can('support', 'update')
      can('support', 'read')
    }

    ability.update(rules)
  }
  return { setUpdateAbility }
}
