<template>
  <SwdButton small primary class="mr-2" @click="openTRCMVerification">
    <img
      class="el-upload-list__item-thumbnail"
      width="70"
      height="70"
      src="../../assets/img/trcm.png"
      alt="maintenance page"
    />
  </SwdButton>
</template>

<script>
import store from '@/store'
import { useShowContentEnv } from '@/hooks/use-show-content-env'

export default {
  name: 'NewTRCMbtn',
  setup() {
    const { showContent } = useShowContentEnv()
    // Function to open TRCM verification in a new tab
    const openTRCMVerification = () => {
      const baseURL = store.state.globalComponents.trcmURL
      const data = store.state.globalComponents.TRCMData

      if (!data) {
        console.error('TRCM Data is not available')
        return
      }

      const encodedData = btoa(JSON.stringify(data))
      const fullURL = `${baseURL}${encodedData}`
      openURLInNewTab(fullURL)
    }

    // Function to open URL in a new tab
    const openURLInNewTab = (url) => {
      window.open(url, '_blank')
    }

    return {
      openTRCMVerification,
      showContent,
    }
  },
}
</script>
