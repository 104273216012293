import router from '../router/index'
import ROLE from "../enums/roles"
export function useRoutRedirect() {
  const routRedirect = async ({ role, userId }) => {
    if (role === ROLE.ADVISOR) router.push({ name: 'advisor/dashboard' })
    if (role === ROLE.LEAD) router.push({ name: 'lead/dashboard' })
    if (role === ROLE.ADMIN) router.push({ name: 'admin/dashboard' })
    if (role === ROLE.CEO) router.push({ name: 'ceo/dashboard' })
    if (role === ROLE.ASSISTANT) router.push({ name: 'advisor/dashboard' })
    if (role === ROLE.SUPPORT) router.push({ name: 'support/dashboard' })
    if (role === ROLE.CLIENT) router.push({ name: `client/member-details`, params: { id: userId } })
  }
  return { routRedirect }
}
