<template>
  <SwdButton primary small :disabled="isLoading" class="w-[100px] ml-4" @click="showModal">
    <SwdSpinner v-show="isLoading" class="mr-2" />
    {{ getText(company) }}
  </SwdButton>

  <el-dialog v-model="dialogVisible" title="Select Company" width="55%">
    <div class="flex flex-wrap">
      <div
        v-for="item in data.data"
        :key="item.id"
        class="border border-main-gray p-2 m-2 rounded-lg hover:border-border-blue"
        :class="{ 'border-main-blue': item.id === currentCompany.id }"
      >
        <div
          class="py-2 text-center text-main cursor-default"
          :class="{ 'text-primary font-semibold': item.id === currentCompany.id }"
        >
          {{ item.name }}
        </div>
        <div class="flex flex-col">
          <div class="text-center mb-2">
            <el-button type="primary" plain size="small" :icon="Finished" class="w-28" @click="newTabCompany(item)">
              Open new tab
            </el-button>
          </div>
          <div class="text-center">
            <el-button type="success" plain size="small" :icon="Link" class="w-28" @click="chooseCompany(item)">
              Choose
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <div class="flex justify-end">
          <SwdButton info small class="w-[100px]" @click="dialogVisible = false">Cancel</SwdButton>
        </div>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useFetchCompanies } from '@/api/use-fetch-companies'
import { Edit, Finished, Link } from '@element-plus/icons-vue'

export default {
  name: 'SelectCompany',
  setup() {
    const store = useStore()
    const router = useRouter()
    const company = ref()
    const options = ref([])
    const dialogVisible = ref(false)

    const { isLoading, isError, data } = useFetchCompanies()

    watchEffect(() => {
      if (!isLoading.value) {
        options.value = data.value.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          }
        })
        company.value = data.value.data.find((item) => item.id === store.state.globalComponents.currentCompanyId).name
      }
    })

    const currentCompany = computed(() => {
      return data.value.data.find((item) => item.id === store.state.globalComponents.currentCompanyId)
    })

    const newTabCompany = (item) => {
      store.commit('globalComponents/setCurrentCompanyId', item.id)
      company.value = item.name
      dialogVisible.value = false
      let routeData = router.resolve({ name: 'ceo/dashboard' })
      window.open(routeData.href, '_blank')
    }

    const chooseCompany = (item) => {
      store.commit('globalComponents/setCurrentCompanyId', item.id)
      company.value = item.name
      dialogVisible.value = false
      let routeData = router.resolve({ name: 'ceo/dashboard' })
      window.open(routeData.href, '_self')
    }

    const getText = (text) => {
      if (text && text.length > 10) return text.slice(0, 10) + '...'
      return text
    }

    const showModal = () => {
      dialogVisible.value = true
    }

    return {
      company,
      options,
      isLoading,
      isError,
      data,
      Edit,
      dialogVisible,
      Finished,
      Link,
      newTabCompany,
      chooseCompany,
      getText,
      currentCompany,
      showModal,
    }
  },
}
</script>
